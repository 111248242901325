<template>
    <div class="header">
        <div class="logo-container">
            <img v-bind:src=this.providerLogo alt="logo 'compañia'" height="72">
        </div>
        <h1>Bienvenido al nuevo portal, donde lo importante eres tú.</h1>
        <p>Te presentamos el nuevo portal transaccional pensado para ti</p>
    </div>
</template>

<script>
export default {
    name: 'Header-Component',
    props: {
        providerLogo: String,
    },
}
</script>
<style>
.header {
    width: 100%;
    padding: 30px;
}

.logo-container img {
    height: 45px;
}
</style>