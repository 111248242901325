<template>
    <div class="home-slider">
        <div class="home-slider-images-container">
            <img src="../../assets/carousel/slide_console.png" alt="" />
            <img src="../../assets/carousel/slide_phone.png" alt="" />
            <img src="../../assets/carousel/slide_speaker.png" alt="" />
        </div>
    </div>
</template>
<script>
export default {
    name: 'Carousel-Component',
}
</script>

<style>
* {
    margin: 0;
    padding: 0;
}

.home-slider {
    margin: 0 auto;
    width: 60vw;
    max-width: 500px;
    overflow: hidden;
    
}

.home-slider-images-container {
    display: flex;
    align-items: center;
    animation: cambio 15s infinite alternate;
    animation-timing-function: linear;
    
}

.home-slider img {
    max-width: 500px;
    width: 60vw;
}

@supports (object-fit: cover) {
    .home-slider img {
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}

@keyframes cambio {
    0% {
        margin-left: 0;
    }

    20% {
        margin-left: 0%;
    }

    40% {
        margin-left: -100%;
    }

    60% {
        margin-left: -100%;
    }

    80% {
        margin-left: -200%;
    }

    100% {
        margin-left: -200%;
    }
}
</style>