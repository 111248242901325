<template>
    <div class="footer">
        <div class="footer-items">
            <div class="footer-item">
                <img src="../../assets/icons/icon-credit.svg" alt="Credito" />
                <h4>Compra online</h4>
            </div>
            <div class="footer-item">
                <img src="../../assets/icons/icon-truck.svg" alt="Transporte" />
                <h4>Entrega garantizada</h4>
            </div>
            <div class="footer-item">
                <img src="../../assets/icons/icon-pig.svg" alt="Ahorro" />
                <h4>Las mejores ofertas</h4>
            </div>
        </div>
        <a href="/" class="footer-btn">Ingresar</a>
    </div>
</template>

<script>
export default {
    name: 'Footer-Component',
}
</script>
<style>
.footer {
    border-top: 2px solid #fff;
    padding: 10px 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.footer-items {
    display: flex;
    justify-content: space-evenly;
}

.footer-item {
    display: flex;
    align-items: center;
    margin: 0 10px;
}

.footer-item h4 {
    font-size: 15px;
    margin: 0 0 0 10px;
}

.footer-btn {
    display: none;
}

@media (max-width: 699px) {
    .footer-items {
        flex-direction: column;
        align-items: center;
    }

    .footer-btn {
        display: block;
        text-align: center;
        text-decoration: none;
        color: #fff;
        cursor: pointer;
        background-color: #e63027;
        margin: 20px auto;
        width: 80%;
        padding: 10px;
        border-radius: 5px;
        font-weight: bold;
    }
}
</style>