<template>
  <div class="banner-container">
    <Header :providerLogo="this.providerLogo" />
    <Carousel />
    <Footer />
  </div>
</template>

<script>
import Header from '../Header/Header.vue'
import Carousel from '../Carousel/Carousel.vue'
import Footer from '../Footer/Footer.vue'
export default {
  name: 'Banner-Component',
  components: {
    // HelloWorld,
    Header,
    Carousel,
    Footer
  },
  props: {
    providerLogo: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.banner-container {
  background: var(--banner-bg) ;
  color: #fff;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (min-width: 700px) {
  .banner-container {
    width: 100%;
    background: var(--banner-bg) ;
  }
}
</style>
